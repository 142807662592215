import React, { useState, useEffect, useRef } from 'react';
import logo from "../assets/images/Neoin Logo.svg";
// import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

// import logo1 from '../assets/images/icon/Interactive.svg'
// import logo2 from '../assets/images/icon/Team.svg'
// import logo3 from '../assets/images/icon/Group.svg'
import { ReactComponent as EnterpriseIcon } from '../assets/images/header-icons/Enterprise-Saas.svg';
import { ReactComponent as ConsumerIcon } from '../assets/images/header-icons/ai-enabled-scan.svg';
import { ReactComponent as AIEnableIcon } from '../assets/images/header-icons/ai-enabled-scan.svg';
import { ReactComponent as BiometricsIcon } from '../assets/images/header-icons/biometrics.svg';
import { ReactComponent as DaLocalIcon } from '../assets/images/header-icons/dalocal-icon.svg';
import { ReactComponent as DigiBusinessCardIcon } from '../assets/images/header-icons/Digital-business-card.svg';
import { ReactComponent as DigiEngageIcon } from '../assets/images/header-icons/digtial-engagement.svg';
import { ReactComponent as EventsIcon } from '../assets/images/header-icons/events-and-conference.svg';
import { ReactComponent as GeotagIcon } from '../assets/images/header-icons/Geotag.svg';
import { ReactComponent as KycIcon } from '../assets/images/header-icons/kyc-and-cdd.svg';
import { ReactComponent as MfaIcon } from '../assets/images/header-icons/mfa.svg';
import { ReactComponent as NfcIcon } from '../assets/images/header-icons/nfc.svg';
import { ReactComponent as PpfScoreIcon } from '../assets/images/header-icons/PPF-score.svg';
import { ReactComponent as QrCallIcon } from '../assets/images/header-icons/qr-call-sms.svg';
import { ReactComponent as VirtualBgIcon } from '../assets/images/header-icons/virtual-background.svg';
// import support from '../assets/images/icon/Support.png'

function Header() {

    const location = useLocation();

    const isHamburger = useMediaQuery({ maxWidth: 992 });

    const isResponsive = useMediaQuery({ maxWidth: 576 });

    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    // Only for desktop: hover to open
    const handleMouseEnter = () => {
        if (!isHamburger) {
            setIsOpen(true);
        }
    };

    // Corrected to close on mouse leave for desktop
    const handleMouseLeave = () => {
        if (!isHamburger) {
            setIsOpen(false);
        }
    };

    const handleMobileClick = () => {
        if (isHamburger) {
            setIsOpen(!isOpen);
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest('.popup-dropdown-container') === null) {
            setIsOpen(false);
        }
    };

    // Attach click event listener to document to handle clicks outside of the dropdown
    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu open/close

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle menu state
    };

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false); // State to track submenu open/close

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen); // Toggle submenu state
    };

    const [isCompanySubMenuOpen, setIsCompanySubMenuOpen] = useState(false); // State to track Company submenu open/close
    const [isPlatformSubMenuOpen, setIsPlatformSubMenuOpen] = useState(false); // State to track Platform submenu open/close
    const [isSolutionSubMenuOpen, setIsSolutionSubMenuOpen] = useState(false); // State to track Solutions submenu open/close
    const [isFeatureSubMenuOpen, setIsFeatureSubMenuOpen] = useState(false); // State to track Features submenu open/close

    const [platformSize, setPlatformSize] = useState({ height: 0, width: 0 });
    const [solutionSize, setSolutionSize] = useState({ height: 0, width: 0 });
    const [featureSize, setFeatureSize] = useState({ height: 0, width: 0 });
    const platformRef = useRef(null);
    const solutionRef = useRef(null);
    const featureRef = useRef(null);

    useEffect(() => {
        if (isPlatformSubMenuOpen && platformRef.current) {
            setPlatformSize({
                height: platformRef.current.offsetHeight,
                width: platformRef.current.offsetWidth
            }); 
        }
    }, [isPlatformSubMenuOpen]);

    useEffect(() => {
        if (isSolutionSubMenuOpen && solutionRef.current) {
            setSolutionSize({
                height: solutionRef.current.offsetHeight,
                width: solutionRef.current.offsetWidth
            });
        }
    }, [isSolutionSubMenuOpen]);

    useEffect(() => {
        if (isFeatureSubMenuOpen && featureRef.current) {
            setFeatureSize({
                height: featureRef.current.offsetHeight,
                width: featureRef.current.offsetWidth
            });
        }
    }, [isFeatureSubMenuOpen]);

    const toggleCompanySubMenu = () => {
        setIsCompanySubMenuOpen(!isCompanySubMenuOpen); // Toggle Company submenu state
    };

    const togglePlatformSubMenu = () => {
        setIsPlatformSubMenuOpen(!isPlatformSubMenuOpen); // Toggle Platform submenu state
    };

    const toggleSolutionSubMenu = () => {
        setIsSolutionSubMenuOpen(!isSolutionSubMenuOpen); // Toggle Solutions submenu state
    };

    const toggleFeatureSubMenu = () => {
        setIsFeatureSubMenuOpen(!isFeatureSubMenuOpen); // Toggle Features submenu state
    };

        /* for neoin active */
        const [isneoinActive,setisneoinActive]=useState(false)

        const pathArray = ['/sme','/bank','/associations','/professionals','/genz','/individuals','/students','/dalocalcommingsoon','/digital-card','/events','/access-mgmt','/digital_engagement', '/kyc_cdd','/feature']; 
    
        useEffect(() => {
            checkPath();
        }, [location.pathname]);
    
        const checkPath = () => {
            for (let i = 0; i < pathArray.length; i++) {
                if (location.pathname === pathArray[i]) {
                    setisneoinActive(true);
                }
            }
        };

        const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Activate the class if the page is scrolled (more than 1px for example)
      setIsActive(window.scrollY > 1);
    };

    // Listen for page scroll events
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

    return (
        <>
            <header className={`header-section ${isActive ? 'active' : ''}`}>
                <div className="container">
                    <div className="header-wrapper">
                        {isHamburger ? ( // Smaller Screens: Tabs and Mobile phones (<= 992px)
                            <div className='mobile-header'>
                                <div className="logo">
                                    <a href="/">
                                        <img src={logo} alt="logo" />
                                    </a>
                                </div>
                                <ul className={`menu ${isMenuOpen ? 'active' : ''}`}>
                                    <li>
                                        <a href="/" className={location.pathname === '/' ? 'menu-link active' : 'menu-link'}>Home</a>
                                    </li>
                                    <li>
                                        <a href="/about" className={location.pathname === '/about' ? 'menu-link active' : 'menu-link'}>About Us</a>
                                    </li>
                                    <li>
                                        <div className="submenu-toggle" onClick={toggleCompanySubMenu}> {/* Click handler for submenu toggle */}
                                            <a id='neoin-mobile-header'>Neoin
                                                <i className={`fas fa-chevron-down ml-2 ${isCompanySubMenuOpen ? 'rotate-up' : ''}`}></i> {/* Rotate arrow icon based on submenu state */}
                                            </a>
                                        </div>
                                        <div className=''>
                                            <ul className={`submenu ${isCompanySubMenuOpen ? 'active' : ''}`}> {/* Apply 'active' class when submenu is open */}
                                                <div className='company-submenu'>
                                                    {/* <div className='colortitle'>Connect. Communicate & Engage</div> */}
                                                    <li className={`platform-menu main-submenu ${isPlatformSubMenuOpen ? 'active' : ''}`} style={{
                                                        '--p-dynamic-height': `${platformSize.height}px`,
                                                        '--p-dynamic-width': `${platformSize.width}px`
                                                    }} // Set the CSS variable
                                                        ref={platformRef} data-toggle="collapse" data-target="#platformList"><div className="submenu-toggle" onClick={togglePlatformSubMenu}> {/* Click handler for submenu toggle */}
                                                            <a>Platform
                                                                <i className={`fas fa-chevron-down ml-2 ${isPlatformSubMenuOpen ? 'rotate-up' : ''}`}></i> {/* Rotate arrow icon based on submenu state */}
                                                            </a>
                                                        </div>
                                                        <ul className={`submenu ${isPlatformSubMenuOpen ? 'active' : ''}`}>
                                                            {/* <li> */}
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <EnterpriseIcon className='header-icon' />
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a ><p className='title-strong no-hover pb-2'>Enterprise Saas</p></a>
                                                                    <ul className="platform-list pl-4">
                                                                        <li className='platform-content'><a href='/sme'>SME</a></li>
                                                                        <li className='platform-content'><a href='/bank'>Banks & NBFC</a></li>
                                                                        <li className='platform-content'><a href='/associations'>Associations</a></li>
                                                                        <li className='platform-content'><a href='/professionals'>Professionals</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            {/* </li> */}
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <ConsumerIcon className="header-icon" />
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='#0'><p className='title-strong no-hover pb-2'>Consumer Saas</p></a>
                                                                    <ul className="platform-list pl-4">
                                                                        <li className='platform-content'><a href='/individuals'>Individuals</a></li>
                                                                        <li className='platform-content'><a href='/students'>Students</a></li>
                                                                        <li className='platform-content'><a href='/genz'>Gen Z</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <DaLocalIcon className="header-icon-s"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a><p className='title-strong no-hover pb-2'>Ecosystem</p></a>
                                                                    <ul className="platform-list pl-4">
                                                                        <li className='platform-content'><a href='/dalocalcommingsoon'>DaLocal</a></li>
                                                                        <li className='platform-content'><a href='/dalocalcommingsoon'>Discounts</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </li>
                                                </div>
                                                <div className='company-submenu'>
                                                    <li className={`solution-menu main-submenu ${isSolutionSubMenuOpen ? 'active' : ''}`} data-toggle="collapse" data-target="#solutionList" style={{ '--s-dynamic-height': `${solutionSize.height}px`, '--s-dynamic-width': `${solutionSize.width}px` }} ref={solutionRef}><div className="submenu-toggle" onClick={toggleSolutionSubMenu}> {/* Click handler for submenu toggle */}
                                                        <a>Solutions
                                                            <i className={`fas fa-chevron-down ml-2 ${isSolutionSubMenuOpen ? 'rotate-up' : ''}`}></i> {/* Rotate arrow icon based on submenu state */}
                                                        </a>
                                                    </div>
                                                        <ul className={`submenu ${isSolutionSubMenuOpen ? 'active' : ''}`}>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <DigiBusinessCardIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/digital-card'><p className='title-strong pb-2'>Digital Verified iD</p></a>
                                                                    {/* <p className='title-content'>Learn about your users</p> */}
                                                                    <ul className="platform-list pl-4">
                                                                        <li className='platform-content'><a href='/digital-card?scrollTo=businesscard'>Digital Business Card</a></li>
                                                                        <li className='platform-content'><a href='/digital-card?scrollTo=digisign'>Digital Signature</a></li>
                                                                        <li className='platform-content'><a href='/digital-card?scrollTo=accessmanage'>Access Management</a></li>
                                                                        <li className='platform-content'><a href='/digital-card?scrollTo=digiconsent'>Digital Consent</a></li>
                                                                        <li className='platform-content'><a href='/digital-card?scrollTo=clientmanage'>Client Management</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            {/* <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-1">
                                                                    <img src={logo2} alt='logo1' />
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/digital-card'><p className='title-strong'>Digital Business Card</p></a>
                                                                    <p className='title-content'>Monitor your metrics</p>
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-1">
                                                                    <img src={logo3} alt='logo1' />
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='#0'><p className='title-strong'>Digital Signature</p></a>
                                                                    <p className='title-content'>Measure B2B account health</p>
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-1">
                                                                    <img src={logo3} alt='logo1' />
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/access-mgmt'><p className='title-strong'>Access Management</p></a>
                                                                    <p className='title-content'>Measure B2B account health</p>
                                                                </div>
                                                            </div> */}
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <EventsIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/events'><p className='title-strong'>Events & Conferences</p></a>
                                                                    <p className='title-content'>Explore Events and Conferences</p>
                                                                </div>
                                                            </div>
                                                            {/* <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-1">
                                                                    <img src={logo3} alt='logo1' />
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='#0'><p className='title-strong'>Client Management</p></a>
                                                                    <p className='title-content'>Learn about your users</p>
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-1">
                                                                    <img src={logo1} alt='logo1' />
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='#0'><p className='title-strong'>Digital Consent & Connect</p></a>
                                                                    <p className='title-content'>Learn about your users</p>
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-1">
                                                                    <img src={logo1} alt='logo1' />
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='#0'><p className='title-strong'>Digital Communication</p></a>
                                                                    <p className='title-content'>Call/SMS/Email</p>
                                                                </div>
                                                            </div> */}
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <DigiEngageIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/digital_engagement'><p className='title-strong'>Digital Engagement</p></a>
                                                                    <p className='title-content'>Connect and Engage</p>
                                                                </div>
                                                            </div>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <KycIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='kyc_cdd'><p className='title-strong'>KYC & CDD</p></a>
                                                                    <p className='title-content pb-2'>Verify Safely Online</p>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </li>
                                                </div>
                                                <div className='company-submenu'>
                                                    <li className={`feature-menu main-submenu ${isFeatureSubMenuOpen ? 'active' : ''}`} data-toggle="collapse" data-target="#featureList" style={{ '--f-dynamic-height': `${featureSize.height}px`, '--f-dynamic-width': `${featureSize.width}px` }} ref={featureRef}><div className="submenu-toggle" onClick={toggleFeatureSubMenu}> {/* Click handler for submenu toggle */}
                                                        <a>Features
                                                            <i className={`fas fa-chevron-down ml-2 ${isFeatureSubMenuOpen ? 'rotate-up' : ''}`}></i> {/* Rotate arrow icon based on submenu state */}
                                                        </a>
                                                    </div>
                                                        <ul className={`submenu ${isFeatureSubMenuOpen ? 'active' : ''}`}>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <NfcIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/feature?scrollTo=nfc'><p className='title-strong'>NFC</p></a>
                                                                    <p className='title-content'>Touch/Share/Connect Easily</p>
                                                                </div>
                                                            </div>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <GeotagIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/feature?scrollTo=geotagging'><p className='title-strong'>Geotagging</p></a>
                                                                    <p className='title-content'>Journey Navigation via Geotags</p>
                                                                </div>
                                                            </div>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <AIEnableIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/feature?scrollTo=aiscan'><p className='title-strong'>AI-Enabled Scan</p></a>
                                                                    <p className='title-content'>Scan Smart with AI</p>
                                                                </div>
                                                            </div>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <BiometricsIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/feature?scrollTo=biometrics'><p className='title-strong'>Biometrics</p></a>
                                                                    <p className='title-content'>Traits and Identity Shield</p>
                                                                </div>
                                                            </div>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <VirtualBgIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/feature?scrollTo=virtualbg'><p className='title-strong'>Virtual Background</p></a>
                                                                    <p className='title-content'>Customizable Virtual Background</p>
                                                                </div>
                                                            </div>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <QrCallIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/feature?scrollTo=qrcall'><p className='title-strong'>QR - Call/SMS/EMail</p></a>
                                                                    <p className='title-content'>Instant Contact with QR</p>
                                                                </div>
                                                            </div>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <MfaIcon className="header-icon"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/feature?scrollTo=mfa'><p className='title-strong'>Multi-factor Authentication</p></a>
                                                                    <p className='title-content'>Security in Multiple Layers</p>
                                                                </div>
                                                            </div>
                                                            <div className="row content mb-2">
                                                                <div className="col-1 pb-2 pt-2">
                                                                    <PpfScoreIcon className="header-icon-s"/>
                                                                </div>
                                                                <div className="col-9 pb-2">
                                                                    <a href='/feature?scrollTo=ppf'><p className='title-strong'>PPF Score</p></a>
                                                                    <p className='title-content pb-2'>Personal, Professional, Financial Score</p>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </li>
                                                </div>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <a href="/pricing" className={location.pathname === '/pricing' ? 'menu-link active' : 'menu-link'}>Pricing</a>
                                    </li>
                                    <li>
                                        <a href="/contact" className={location.pathname === '/contact' ? 'menu-link active' : 'menu-link'}>Contact Us</a>
                                    </li>
                                    {isResponsive && (
                                        <div>
                                            <li>
                                                <a href='/login_new' target='_blank'>Login</a>
                                            </li>
                                            {/* <li>
                                                <a href='#0'>Create iD</a>
                                            </li> */}
                                        </div>
                                    )}
                                </ul>
                                <div className="header-bar d-lg-none" onClick={toggleMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="header-right">
                                    <a href={`${process.env.REACT_APP_FRONTEND_URL}/login`} target='_blank' className="header-button d-none d-sm-inline-block">LOG IN</a>
                                    {/* <a href="#0" className="button-create d-none d-sm-inline-block">Create ID</a> */}
                                </div>
                            </div>
                        ) : ( // Larger Screens: Laptops and Desktops (> 992px)
                        <>
                        <div className="logo">
                            <a href="/">
                                <img src={logo} alt="logo" />
                            </a>
                        </div>
                        <ul className="menu">
                            <li>
                                <a href="/" className={location.pathname === '/' ? 'menu-link active' : 'menu-link'}>Home</a>
                            </li>
                            <li>
                                <a href="/about" className={location.pathname === '/about' ? 'menu-link active' : 'menu-link'}>About Us</a>
                            </li>
                            <li>
                                <a className={`menu-item-has-children neoin-header-menu ${isneoinActive ? 'menu-link active' : 'menu-link'}`} onClick={togglePopup} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >Neoin</a>
                                <div className="popup-dropdown-container">
                                    {isOpen && (
                                        <div className="popup_homepage" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <div className="popup-content">
                                                <div className='colortitle pb-2'>Connect. Communicate & Engage</div>
                                                <div className="row">
                                                    <div className="column-box">
                                                        <div className="column">
                                                            <h5 className='colorheader mb-2'>Platform</h5>
                                                            <div className="row content">

                                                            <div className="col-md-2">
                                                            <EnterpriseIcon className='header-icon' />
                                                        </div>

                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <a><p className='title-strong no-hover'>Enterprise Saas</p></a>
                                                                            <p className='title-content'>
                                                                                <ul className="platform-list">
                                                                                    <a href='/sme'><li className='platform-content'>SME</li></a>
                                                                                    <a href='/bank'><li className='platform-content'>Banks & NBFC</li></a>
                                                                                    <a href='/associations'><li className='platform-content'>Associations</li></a>
                                                                                    <a href='/professionals'><li className='platform-content'>Professionals</li></a>
                                                                                </ul>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row content">
                                                                    <div className="col-md-2">
                                                                    <ConsumerIcon className='header-icon'/>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <a><p className='title-strong no-hover'>Consumer Saas</p></a>
                                                                            <p className='title-content'>
                                                                                <ul className="platform-list">
                                                                                    <a href='/individuals'><li className='platform-content'>Individuals</li></a>
                                                                                    <a href='/students'><li className='platform-content'>Students</li></a>
                                                                                    <a href='/genz'><li className='platform-content'>Gen Z</li></a>
                                                                                </ul>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row content">
                                                            <div className="col-md-2">
                                                            <DaLocalIcon className='header-icon-s' />
                                                            </div>
                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <a><p className='title-strong no-hover'>Ecosystem</p></a>
                                                                            <p className='title-content'>
                                                                                <ul className="platform-list">
                                                                                    <a href='/dalocalcommingsoon'><li className='platform-content'>DaLocal</li></a>
                                                                                    <a href='/dalocalcommingsoon'><li className='platform-content'>Discount</li></a>
                                                                                </ul>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row-box">
                                                            <div className="column-box-2">
                                                                <div className='row'>
                                                                    <div className='col-7'><img src={support} alt='support' width={142} height={72} /></div>
                                                                    <div className='col-5'><p className='support-content'>Connect with<br />support Team</p><a className='support-link' href='#0'>Click here</a></div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    <div className="column-1">
                                                        <a className='pl-0'><h5 className='colorheader mt-0'>Solutions</h5></a>
                                                        <div className="row content">

                                                        <div className="col-md-2">
                                                            < DigiBusinessCardIcon className='header-icon' />
                                                        </div>
                                                            <div className="col-md-6">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <a href='/digital-card'><p className='title-strong'>Digital Verified iD</p></a>
                                                                            <p className='title-content'>
                                                                                <ul className="platform-list">
                                                                                    <a href='/digital-card?scrollTo=businesscard'><li className='platform-content'>Digital Business Card</li></a>
                                                                                    <a href='/digital-card?scrollTo=digisign'><li className='platform-content'>Digital Signature</li></a>
                                                                                    <a href='/digital-card?scrollTo=accessmanage'><li className='platform-content'>Access Management</li></a>
                                                                                    <a href='/digital-card?scrollTo=digiconsent'><li className='platform-content'>Digital Consent</li></a>
                                                                                    <a href='/digital-card?scrollTo=clientmanage'><li className='platform-content'>Client Management</li></a>
                                                                                </ul>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        </div>

                                                        <div className="row content">
                                                            {/* <div className="col-md-2">
                                                                <img src={logo3} alt='logo1' />
                                                            </div> */}

                                                        <div className="col-md-2">
                                                            <EventsIcon className='header-icon' />
                                                        </div>

                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='/events'><p className='title-strong'>Events & Conferences</p></a>
                                                                        <p className='title-content'>Explore Events and Conferences</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                
                                                        <div className="row content">

                                                            {/* <div className="col-md-2">
                                                                <img src={logo3} alt='logo1' />
                                                            </div> */}

                                                            <div className="col-md-2">
                                                                < DigiEngageIcon className='header-icon' />
                                                          </div>

                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='/digital_engagement'><p className='title-strong'>Digital Engagement</p></a>
                                                                        <p className='title-content'>Connect and Engage</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row content">
                                                            {/* <div className="col-md-2">
                                                                <img src={logo3} alt='logo1' />
                                                            </div> */}

                                                        <div className="col-md-2">
                                                            <KycIcon  className='header-icon' />
                                                        </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='kyc_cdd'><p className='title-strong'>KYC & CDD</p></a>
                                                                        <p className='title-content'>Verify Safely Online</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="column-2">
                                                        <a href='/feature' className='pl-0'><h5 className='colorheader mt-0'>Features</h5></a>
                                                        <div className="row content">
                                                            {/* <div className="col-md-2">
                                                                <img src={logo1} alt='logo1' />
                                                            </div> */}
                                                            <div className="col-md-2">
                                                            <NfcIcon  className='header-icon' />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        {/* TO scroll to features section*/}
                                                                        <a href='/feature?scrollTo=nfc'><p className='title-strong'>NFC</p></a>
                                                                        <p className='title-content'>Touch/Share/Connect Easily</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row content">
                                                            {/* <div className="col-md-2">
                                                                <img src={logo2} alt='logo1' />
                                                            </div> */}
                                                            <div className="col-md-2">
                                                                <GeotagIcon className='header-icon' />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='/feature?scrollTo=geotagging'><p className='title-strong'>Geotagging</p></a>
                                                                        <p className='title-content'>Journey Navigation via Geotags</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row content">
                                                        <div className="col-md-2">
                                                            <AIEnableIcon className='header-icon' />
                                                        </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='/feature?scrollTo=aiscan'><p className='title-strong'>AI-Enabled Scan</p></a>
                                                                        <p className='title-content'>Scan Smart with AI</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row content">
                                                        <div className="col-md-2">
                                                            <BiometricsIcon className='header-icon' />
                                                        </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='/feature?scrollTo=biometrics'><p className='title-strong'>Biometrics</p></a>
                                                                        <p className='title-content'>Traits and Identity Shield</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row content">
                                                            {/* <div className="col-md-2">
                                                                <img src={logo3} alt='logo1' />
                                                            </div> */}
                                                            <div className="col-md-2">
                                                                <VirtualBgIcon  className='header-icon' />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='/feature?scrollTo=virtualbg'><p className='title-strong'>Virtual Background</p></a>
                                                                        <p className='title-content'>Customizable Virtual Background</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row content">
                                                            {/* <div className="col-md-2">
                                                                <img src={logo3} alt='logo1' />
                                                            </div> */}

                                                        <div className="col-md-2">
                                                            <QrCallIcon  className='header-icon' />
                                                                </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='/feature?scrollTo=qrcall'><p className='title-strong'>QR-Call/SMS/Email</p></a>
                                                                        <p className='title-content'>Instant Contact with QR</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row content">
                                                            {/* <div className="col-md-2">
                                                                <img src={logo3} alt='logo1' />
                                                            </div> */}
                                                            
                                                            <div className="col-md-2">
                                                                <MfaIcon  className='header-icon' />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='/feature?scrollTo=mfa'><p className='title-strong'>Multi-factor Authentication</p></a>
                                                                        <p className='title-content'>Security in Multiple Layers</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row content">
                                                            {/* <div className="col-md-2">
                                                                <img src={logo3} alt='logo1' />
                                                            </div> */}

                                                        <div className="col-md-2">
                                                            <PpfScoreIcon  className='header-icon-s'/>
                                                        </div>
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <a href='/feature?scrollTo=ppf'><p className='title-strong'>PPF Score</p></a>
                                                                        <p className='title-content'>Personal, Professional, Financial Score</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </li>

                            <li>
                                <a href="/pricing" className={location.pathname === '/pricing' ? 'menu-link active' : 'menu-link'}>Pricing</a>
                            </li>
                            <li>
                                <a href="/contact" className={location.pathname === '/contact' ? 'menu-link active' : 'menu-link'}>Contact Us</a>
                            </li>
                            {isResponsive && (
                                <div>
                                    <li>
                                        <a href='/login_new' target='_blank'>Login</a>
                                    </li>
                                    {/* <li>
                                        <a href='#0'>Create iD</a>
                                    </li> */}
                                </div>
                            )}
                        </ul>
                        <div className="header-bar d-lg-none">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="header-right">
                            <a href={`${process.env.REACT_APP_FRONTEND_URL}/login`} className="header-button d-none d-sm-inline-block" target='_blank'>LOG IN</a>
                            {/* <a href="#0" className="button-create d-none d-sm-inline-block" id="banner-btn">Create ID</a> */}
                        </div>
                    </>
                )}

            </div>
        </div>
    </header>

</>

)

}

export default Header
